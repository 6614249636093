
import React from 'react';
import { createRoot } from 'react-dom';
import { MastodonLoader, mastodonReady } from 'lily/components/mastodon-loader.js';

const readyCallback = () => {
  const mountNode = document.getElementById('mastodon');
  const props = JSON.parse(mountNode.getAttribute('data-props'));

  const root = createRoot(mountNode);
  root.render(<MastodonLoader {...props} />);
  mastodonReady.then(async (store) => {
    const { setupBrowserNotifications } = await import('flavours/glitch/actions/notifications');
    store.dispatch(setupBrowserNotifications());

    // when it's ready we have definitely logged in
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const { Workbox } = await import('workbox-window');
      const wb = new Workbox('/sw.js');
      /** @type {ServiceWorkerRegistration} */
      let registration;

      try {
        registration = await wb.register();
      } catch (err) {
        console.error(err);
      }

      if (registration) {
        const registerPushNotifications = await import('flavours/glitch/actions/push_notifications');

        store.dispatch(registerPushNotifications.register());
      }
    }

    perf.stop('main()');
  })
    .catch(() => {});
};

export { readyCallback };
