const loader = {
  'af': true,
  'an': true,
  'ar': true,
  'ast': true,
  'be': true,
  'bg': true,
  'bn': true,
  'br': true,
  'bs': true,
  'ca': true,
  'ckb': true,
  'co': true,
  'cs': true,
  'cy': true,
  'da': true,
  'de': true,
  'el': true,
  'en-GB': true,
  'en': true,
  'eo': true,
  'es-AR': true,
  'es-MX': true,
  'es': true,
  'et': true,
  'eu': true,
  'fa': true,
  'fi': true,
  'fo': true,
  'fr-QC': true,
  'fr': true,
  'fy': true,
  'ga': true,
  'gd': true,
  'gl': true,
  'he': true,
  'hi': true,
  'hr': true,
  'hu': true,
  'hy': true,
  'id': true,
  'ig': true,
  'io': true,
  'is': true,
  'it': true,
  'ja': true,
  'ka': true,
  'kab': true,
  'kk': true,
  'kn': true,
  'ko': true,
  'ku': true,
  'kw': true,
  'la': true,
  'lt': true,
  'lv': true,
  'mk': true,
  'ml': true,
  'mr': true,
  'ms': true,
  'my': true,
  'nl': true,
  'nn': true,
  'no': true,
  'oc': true,
  'pa': true,
  'pl': true,
  'pt-BR': true,
  'pt-PT': true,
  'ro': true,
  'ru': true,
  'sa': true,
  'sc': true,
  'sco': true,
  'si': true,
  'sk': true,
  'sl': true,
  'sq': true,
  'sr-Latn': true,
  'sr': true,
  'sv': true,
  'szl': true,
  'ta': true,
  'tai': true,
  'te': true,
  'th': true,
  'tr': true,
  'tt': true,
  'ug': true,
  'uk': true,
  'ur': true,
  'vi': true,
  'zgh': true,
  'zh-CN': true,
  'zh-HK': true,
  'zh-TW': true,
};

export { loader };

