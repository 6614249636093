import { readyCallback } from 'lily/utils/entrypoint';

import * as perf from 'flavours/glitch/performance';
import ready from 'flavours/glitch/ready';

/**
 * @returns {Promise<void>}
 */
function main() {
  perf.start('main()');

  return ready(readyCallback);
}

export default main;
