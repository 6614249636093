
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { match as matchLocale } from '@formatjs/intl-localematcher';
import { loadLocale as vanillaLoadLocale } from 'flavours/glitch/locales/load_locale';
import { loader } from './locale-loader';

const STORAGE_NAME = 'glitch-lily-locale';
const SETLOCALE_SESSION_ONLY = 'SETLOCALE_SESSION_ONLY';
const SETLOCALE_SAVED = 'SETLOCALE_SAVED';

const storage = window.localStorage;

const defaultLocale = process.env.DEFAULT_LOCALE || 'en';

const localeNames = Object.keys(loader);

const getLocaleImpl = () => {
  if (!storage) {
    return;
  }

  const localeName = storage.getItem(STORAGE_NAME);
  return localeName;
};

const negotiateLocale = () => {
  if (!Intl || !Intl.Locale) {
    return defaultLocale;
  }

  const languagesRequested = (
    navigator && navigator.languages || [navigator.language]
  );

  const negotiated = matchLocale(
    languagesRequested,
    localeNames,
    defaultLocale
  );

  return negotiated;
};

const getLocale = () => {
  return getLocaleImpl() || negotiateLocale();
};

const saveLocale = (localeName) => {
  if (!storage) {
    return;
  }

  storage.setItem(STORAGE_NAME, localeName);
};

const setLocale = (localeName) => {
  return saveLocale(localeName) ? SETLOCALE_SAVED : SETLOCALE_SESSION_ONLY;
};

const useLocale = () => {
  const locale = getLocale();
  const [localeState, setLocaleState] = useState(locale);

  // Mastodon now uses the lang attr in html to determine locale
  useEffect(() => {
    const root = document.querySelector('html');
    if (root) {
      root.lang = localeState;
      console.log('force loading locale:', localeState);
      vanillaLoadLocale(/* force =*/ true);
    } else {
      console.warn('html element not available, cannot set locale');
    }
  }, [localeState]);

  const setLocaleAndSetState = useCallback(async (localeName) => {
    setLocale(localeName);
    setLocaleState(localeName);
  }, [setLocaleState]);

  const obj = {
    locale: localeState,
    setLocale: setLocaleAndSetState,
  }

  return obj;
};

const LangSettingsContext = React.createContext();

export {
  localeNames,
  useLocale,
  getLocale,
  LangSettingsContext,
};
